.tareaModalOverlay {
    background: rgba(255, 255, 255, 0.88);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9990;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tareaModalContent {
    background: #fff;
    box-shadow: 0 0 100px rgba(0, 0, 0, 0.15);
    padding: 40px;
    width: 92vw;
    max-height: 92vh;
    position: relative;
    z-index: 9991;
    display: flex;
    flex-direction: column;

    header {
        .arrows {
            display: flex;
            justify-content: space-between;
            margin-bottom: 0.3rem;

            button {
                &:hover {
                    text-decoration: none !important;
                }
            }
        }

        .tarea-finalizada {
            color: red;
        }

        .subheader {
            display: flex;
            justify-content: space-between;
            height: 30px;

            & .tarea-detalle-tags {
                height: 100%;
            }

            & .tarea-detalle-users {
                span {
                    padding: 0 6px;
                }
            }
        }

        .btn-close {
            position: relative;
            margin-bottom: 2rem;
        }
    }

    .main_content {
        flex-grow: 1;
        overflow: hidden;
        flex-wrap: nowrap;
        min-height: 200px;

        .has-scroll {
            overflow-y: auto;
            min-height: 511px;
            max-height: 511px;
        }

        .comments {
            display: flex;
            flex-direction: column;

            .comment_list {
                flex-grow: 1;
                overflow-y: auto;
                min-height: 400px;
                max-height: 400px;
            }
        }

        .edit-input {
            width: 100% !important;
            height: 100% !important;
        }
    }
}
