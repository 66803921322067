.modalWrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.96);
    display: flex;
    align-items: flex-start;
    padding-top: 3.8%;
    justify-content: center;
    z-index: 9;
}

.modalContent {
    width: 900px;
    background: #fff;
    box-shadow: 0 0 80px rgba(0,0,0,.4);
    max-height: 80%;

    .close {

    }
}
